import React from "react";
import { DatePicker } from "antd";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Space, Typography } from "antd";

const { Text, Link } = Typography;

export default function Privacy() {
  return (
    <>
      <div className="container">
        <h1>Privacy and Policy</h1>
        <DatePicker />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField label="Basic date field" />
        </LocalizationProvider>
        <Space direction="vertical">
          {" "}
          <Text keyboard>Ant Design (keyboard)</Text>
          <Text code>Ant Design (code)</Text>
        </Space>
      </div>
    </>
  );
}
