import React from "react";

function Contact() {
  return (
    <>
      <div className="container">
        <div class="box-border p-4 bg-green-500 m4">Contact</div>
      </div>
    </>
  );
}

export default Contact;
