import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Notion from "./pages/Notion";
// import Login from "./pages/LoginPage";
// import Blog from "./pages/Blog";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Resume from "./pages/Resume";
import Header from "./components/Header";
import BMS from "./pages/projects/BMS";
import Signup from "./pages/Signup";
import Linktree from "./pages/Linktree";
import Particle from "./components/Particle";

function App() {
  return (
    <>
      <Particle id="particles" />
      <Router>
        <Header />
        <br />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          {/* <Route exact path="/login" element={<Login />}></Route> */}
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/notion" element={<Notion />}></Route>
          {/* <Route exact path="/blog" element={<Blog />}></Route> */}
          <Route exact path="/privacy" element={<Privacy />}></Route>
          <Route exact path="/terms" element={<Terms />}></Route>
          <Route exact path="/resume" element={<Resume />}></Route>
          <Route exact path="/bms" element={<BMS />}></Route>
          <Route exact path="/signup" element={<Signup />}></Route>
          <Route exact path="/linktree" element={<Linktree />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
