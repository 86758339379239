import React from "react";
import Intro from "../components/Intro";
import Education from "../components/Education";
import Contact from "../components/Contact";
import Experience from "../components/Experience";
import Projects from "../components/Projects";
import Skills from "../components/Skills";
import Particle from "../components/Particle";
import "../assets/css/particles.css";
import Header from "../components/Header";

function Home() {
  return (
    <>
      <Intro />
      <Skills />
      <Experience />
      <Projects />
      <Education />
      <Contact />
      {/* <Particle id="particles" /> */}
    </>
  );
}

export default Home;
