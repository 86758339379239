import React from "react";

function Intro() {
  return (
    <>
      <div className="container">
        <div className="box-border p-4 bg-red-500 text-black">Introduction</div>
      </div>
    </>
  );
}

export default Intro;
