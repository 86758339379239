import React from "react";

export default function Linktree() {
  return (
    <>
      <div className="container grid flex-col space-y-4 justify-items-center">
        <div className="flex -space-x-2 overflow-hidden">
          <img
            className="inline-block h-22 w-22 rounded-full ring-2 ring-white"
            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
        </div>
        <a className="block max-w p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Twitter
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            twitter/@venugopalpudur Here is my twitter content. please follow
            me.
          </p>
        </a>
        <a className="block max-w p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Twitter
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            twitter/@venugopalpudur Here is my twitter content
          </p>
        </a>
        <a className="block max-w p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Twitter
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            twitter/@venugopalpudur Here is my twitter content
          </p>
        </a>
        <a className="block max-w p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Twitter
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            twitter/@venugopalpudur Here is my twitter content
          </p>
        </a>
      </div>
    </>
  );
}
