import React from "react";

function Projects() {
  return (
    <>
      <div className="container">
        <div class="box-border p-4 bg-yellow-500 m4">Projects</div>
      </div>
    </>
  );
}

export default Projects;
