import React from "react";

function Experience() {
  return (
    <>
      <div className="container">
        <div class="box-border p-4 bg-orange-500 m4">Experience</div>
      </div>
    </>
  );
}

export default Experience;
