import React from "react";
import { Col, Divider, Row } from "antd";

const style = {
  background: "#0092ff",
  padding: "8px 0",
};

export default function Contact() {
  return (
    <>
      <div className="container">
        <h1>Terms and Conditions</h1>
      </div>
      <Divider orientation="left">Horizontal</Divider>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <div style={style}>col-6</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div style={style}>col-6</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div style={style}>col-6</div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div style={style}>col-6</div>
        </Col>
      </Row>
    </>
  );
}
